import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="g7-command-codes-form"
export default class extends Controller {
    static targets = ["locationSelect", "spaceSelect", "bikeHangarSelect"]

    connect() {
        this.spaceSelectTarget.disabled = true
        this.bikeHangarSelectTarget.disabled = true
        $(this.locationSelectTarget).on('select2:select', (e) => this._processLocationChange(e))
    }

    _processLocationChange(e) {
        let locationId = e.params.data["id"]
        this.spaceSelectTarget.selectedIndex = -1
        this._removeSelectOptions(this.spaceSelectTarget)
        this.bikeHangarSelectTarget.selectedIndex = -1
        this._removeSelectOptions(this.bikeHangarSelectTarget)

        if (locationId) {
            this._setSpaceOptions(locationId)
            this._setBikeHangarsOptions(locationId)
        } else {
            this.spaceSelectTarget.disabled = true
            this.bikeHangarSelectTarget.disabled = true
        }
    }

    _setSpaceOptions(locationId) {
        $.ajax({
            type: 'get',
            dataType: 'json',
            url: '/g7_spaces?location_id=' + locationId,
            success: (data) => this._processDataForOptions(data, this.spaceSelectTarget)
        })
    }

    _setBikeHangarsOptions(locationId) {
        $.ajax({
            type: 'get',
            dataType: 'json',
            url: '/bike_hangars?location_id=' + locationId,
            success: (data) => this._processDataForOptions(data, this.bikeHangarSelectTarget)
        })
    }

    _processDataForOptions(data, selectElement) {
        if (!data || data.length === 0) {
            selectElement.disabled = true
            return
        }
        selectElement.add(new Option('', '', false, true))
        data.forEach((item) => {
            let option = new Option(item.name, item.id, false, false)
            selectElement.add(option)
        })
        selectElement.disabled = false
    }

    _removeSelectOptions(selectElement) {
        while (selectElement.options.length > 0) {
            selectElement.remove(0)
        }
    }
}
